import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/types';
import { BaseLiderSeguimiento } from '../../../redux/pdfSombra/pdfSombraActions';
import { returnRecomentadion } from './TableGraph3';

export const obtenerColorPorPuntos = (puntos: string) => {
  if (puntos === '3') return 'green';
  if (puntos === '2') return 'yellow';
  return 'red';
};
export function separarNumeroYTexto(frase: string) {
  // Utilizamos una expresión regular para separar el número del texto
  const regex = /^(\d+)\.\s*(.*)$/;
  const resultado = frase.match(regex);
  if (resultado) {
    return {
      numero: resultado[1], // El número (como string)
      texto: resultado[2] // El texto
    };
  } else {
    return {
      numero: '-', // El número (como string)
      texto: '-' // El texto
    }; // Si no coincide con el formato esperado
  }
}
export const orderBaseLiderSeguimiento = (questions?: BaseLiderSeguimiento[]) => {
  if (questions) {
    const orderedQuestions = questions.sort((a, b) => a.pregunta - b.pregunta);
    const rango1 = orderedQuestions.filter((p) => p.pregunta >= 0 && p.pregunta <= 10);
    const rango2 = orderedQuestions.filter((p) => p.pregunta >= 11 && p.pregunta <= 14);
    const resto = orderedQuestions.filter((p) => p.pregunta > 14);
    return {
      rango1,
      rango2,
      resto
    };
  }
  return { rango1: [], rango2: [], resto: [] };
};

const TablaRubricaDetail7 = memo(() => {
  const { shadowProcess } = useSelector((state: AppState) => state.pdfSombra);

  const { rango1, rango2, resto } = orderBaseLiderSeguimiento(
    shadowProcess?.base_lider.seguimiento
  );

  const ContentRow = (result: BaseLiderSeguimiento, rowKey: number, name: string) => {
    return (
      <>
        <div className="row" style={{ alignItems: 'center' }} key={`${rowKey}-${name}`}>
          <div className="col ">{result.texto_pregunta}</div>
          <div className="col-1 col-title center">
            {separarNumeroYTexto(result.etiqueta).numero}
          </div>
          <div className={`col-2 col-title center `}>
            <div
              className={`pills ${obtenerColorPorPuntos(separarNumeroYTexto(result.etiqueta).numero)}`}
            >
              {separarNumeroYTexto(result.etiqueta).texto}
            </div>
          </div>
        </div>
        <div className="dvider light" />
      </>
    );
  };

  return (
    <div className="table-four content">
      <div className="banner" />

      <div className="row">
        <div className="col center">
          <h1>Rúbrica de Evaluación Jefe Inmediato</h1>
        </div>
      </div>

      <div className="row">
        <div className="col col-title">
          El colaborador tiene un nivel de desempeño bajo, adecuado o suficiente al realizar las
          siguientes actividades:
        </div>
      </div>
      <div className="dvider" />

      <div className="row" style={{ marginTop: '18px' }}>
        <div className="col col-title">{shadowProcess?.seccion_servicio}</div>
        <div className="col-1 col-title center">Puntos</div>
        <div className="col-2 col-title center">Etiqueta</div>
      </div>

      {rango1.map((result, key) => {
        return ContentRow(result, key, 'rango17');
      })}

      <div className="row">
        <div className="col col-title" style={{ marginTop: '18px' }}>
          {shadowProcess?.seccion_herramientas}
        </div>
        <div className="col-1 col-title center"></div>
        <div className="col-2 col-title center"></div>
      </div>
      {rango2.map((result, key) => {
        return ContentRow(result, key, 'rango27');
      })}
      <div className="row">
        <div className="col col-title" style={{ marginTop: '18px' }}>
          {shadowProcess?.seccion_formatos}
        </div>
        <div className="col-1 col-title center"></div>
        <div className="col-2 col-title center"></div>
      </div>
      {resto.map((result, key) => {
        return ContentRow(result, key, 'resto7');
      })}

      <div className="row" style={{ alignItems: 'flex-end' }}>
        <div className="col-7 border" style={{ marginTop: '18px' }}>
          <div className="col-title">Acciones a realizar</div>
          <br />
          {returnRecomentadion(shadowProcess)}
        </div>

        <div className="col-1 center border">
          <div className="col-title">Total puntos obtenidos</div>
          <br /> <div className="results">{shadowProcess?.base_lider.calificacion}</div>
        </div>

        <div className="col-2 center">
          Escala de valoración total
          <br />
          <div className="row" style={{ alignItems: 'center', flexDirection: 'column', gap: 4 }}>
            <div className={`pills dark-red`}>01 a 30 Debe mejorar</div>
            <div className={`pills dark-yellow`}>31 a 50 Aceptable</div>
            <div className={`pills dark-green`}>51 a 60 Excelente</div>
          </div>
        </div>
      </div>
    </div>
  );
});
TablaRubricaDetail7.displayName = 'TablaRubricaDetail7';

export default TablaRubricaDetail7;
