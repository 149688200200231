import React, { useState } from 'react';
import './updateDbModal.css';
import Upload from '../../assets/upload-logo.png';
import { uploadColaboradores } from '../../services/uploadColaboradores';
import axios from 'axios';
interface UpdateDbModalProps {
  isUpdateDbModal: (isOpen: boolean) => void;
  isPostSuccess: (success: boolean) => void;
  isLoadingAlert: (loading: boolean) => void;
  setIsUploading: (isUploading: boolean) => void;
}

const UpdateDbModal: React.FC<UpdateDbModalProps> = ({
  isUpdateDbModal,
  isPostSuccess,
  isLoadingAlert,
  setIsUploading
}) => {
  const [colabsFile, setColabsFile] = useState<File | null>(null);
  const [colabsFileConverted, setColabsFileConverted] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [isUploading, setIsUploadingLocal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cancelTokenSource, setCancelTokenSource] = useState<any>(null);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const file = e.target.files?.[0] || null;
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileConverted = reader.result as string;
        const base64File = fileConverted.split(',')[1];
        setColabsFileConverted(base64File);
      };

      reader.readAsDataURL(file);
      setColabsFile(file);
    }
  };

  const changeModalState = () => {
    isUpdateDbModal(false);
  };

  const uploadColaboradoresList = async () => {
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    setIsUploadingLocal(true);
    setIsUploading(true);
    isLoadingAlert(true);
    const token = sessionStorage.getItem('token');

    if (!token || !colabsFileConverted) {
      console.error('No token available or no file to upload');
      setErrorMessage('No se ha proporcionado un token o archivo para la carga.');
      setIsUploadingLocal(false);
      setIsUploading(false);
      isLoadingAlert(false);
      return;
    }

    const document = { file: colabsFileConverted };

    try {
      const result = await uploadColaboradores(
        document,
        token,
        setProgress,
        setErrorMessage,
        newCancelTokenSource,
        30000
      ); // Añadir el tiempo de espera como argumento

      if (result) {
        isPostSuccess(true);
        changeModalState();
      } else {
        isPostSuccess(false);
        setErrorMessage('Hubo un problema durante la carga. Por favor, inténtalo de nuevo.');
      }
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Carga cancelada por el usuario o tiempo de espera excedido', error.message);
        setErrorMessage(
          'La carga ha sido cancelada por el usuario o el tiempo de espera ha excedido.'
        );
      } else {
        console.error('Error durante la carga', error);
        setErrorMessage('Hubo un problema durante la carga. Por favor, inténtalo de nuevo.');
      }
      isPostSuccess(false);
    } finally {
      setIsUploadingLocal(false);
      setIsUploading(false);
      isLoadingAlert(false);
      setProgress(0);
      setColabsFile(null);
      setColabsFileConverted('');
    }
  };

  const cancelUpload = () => {
    cancelTokenSource.cancel('Carga cancelada por el usuario');
    setIsUploadingLocal(false);
    setIsUploading(false);
    isLoadingAlert(false);
    setProgress(0);
  };

  const getProgressGradient = (percentage: number) => {
    if (percentage === 100) {
      return 'linear-gradient(to right, #4ca1af, #c4e0e5)';
    } else if (percentage >= 75) {
      return 'linear-gradient(to right, #56ab2f, #a8e063)';
    } else if (percentage >= 50) {
      return 'linear-gradient(to right, #f7b733, #ffdd00)';
    } else if (percentage >= 25) {
      return 'linear-gradient(to right, #ff8008, #ffc837)';
    } else if (percentage >= 10) {
      return 'linear-gradient(to right, #ff416c, #ff4b2b)';
    } else if (percentage > 0) {
      return 'linear-gradient(to right, #630000, #190a05)';
    } else {
      return 'linear-gradient(to right, #e0e0e0, #e0e0e0)';
    }
  };

  return (
    <div className="updatedb-modal">
      <div className="updatedb-container">
        <p>{isUploading ? 'Actualizando Datos' : 'Actualizar Datos'}</p>
        {isUploading ? (
          <div className="uploading-info">
            <p>
              <span className="uploading-title">Subiendo archivo:</span>{' '}
              <span className="file-name">{colabsFile ? colabsFile.name : ''}</span>
            </p>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{
                  width: `${progress}%`,
                  background: getProgressGradient(progress),
                  color: progress > 20 ? 'white' : 'black'
                }}
              >
                {progress}%
              </div>
            </div>
          </div>
        ) : (
          <div className="input-container">
            <div className="upload-file">
              <img src={Upload} alt="upload-logo" />
              <p>{colabsFile === null ? 'Cargar archivo' : colabsFile.name}</p>
            </div>
            <input type="file" id="uploadDb" name="Cargar archivo" onChange={handleFile} />
          </div>
        )}
        {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
        <div className="upload-file-buttons">
          {isUploading ? (
            <button className="cancel-button" onClick={cancelUpload}>
              Cancelar Carga
            </button>
          ) : (
            <button className="cancel-button" onClick={changeModalState}>
              CANCELAR
            </button>
          )}
          <button
            className={`accept-button ${isUploading || !colabsFileConverted ? 'disabled' : ''}`}
            onClick={uploadColaboradoresList}
            disabled={isUploading || !colabsFileConverted}
          >
            {isUploading ? 'CARGANDO...' : 'ACEPTAR'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateDbModal;
