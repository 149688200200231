export interface ShadowProcessDetailI {
  nomina: string;
  nombre: string;
  puesto: string;
  nomina_lider: string;
  nombre_lider: string;
  nomina_encargado: string;
  nombre_encargado: string;
  base_par: BasePar;
  base_lider: BaseLider;
  seccion_formatos: string;
  seccion_herramientas: string;
  seccion_servicio: string;
}

export interface BaseLider {
  calificacion: number;
  seguimiento: BaseLiderSeguimiento[];
  formatos: string;
  servicio: string;
  herramientas: string;
}

export interface BaseLiderSeguimiento {
  pregunta: number;
  texto_pregunta: string;
  etiqueta: Etiqueta;
}

export enum Etiqueta {
  The1DebeMejorar = '1. Debe mejorar',
  The2Aceptable = '2. Aceptable'
}

export interface BasePar {
  calificacion: number;
  seguimiento: BaseParSeguimiento[];
}

export interface BaseParSeguimiento {
  pregunta: number;
  texto_pregunta: string;
  respuesta: string;
}

export const getShadowDetailsAction = (shadowData: ShadowProcessDetailI) => {
  return {
    type: 'SHADOW_DETAILS',
    payload: shadowData
  };
};

export const setDynamicHeight = (height: number) => {
  return {
    type: 'dynamicHeight',
    payload: height
  };
};

export const setDynamicHeight2 = (height: number) => {
  return {
    type: 'dynamicHeight2',
    payload: height
  };
};
