import React, { memo } from 'react';
import Table1 from './components/Table1';
import './RenderPdfSombra.scss';
import TableGraph3 from './components/TableGraph3';
import Table2 from './components/Table2';
import TablaRubricaDetail7 from './components/TablaRubricaDetail7';
import TablaRubricaDetailPar9 from './components/TablaRubricaDetailPar9';
import { Chart, registerables } from 'chart.js';
import BreadCrumb from '../../components/breadCrumb/breadCrumb';
import CapacitacionStyles from '../../styles/Detalles.module.css';
import useRenderPdfSombra from './hooks/useRenderPdfSombra';

Chart.register(...registerables);

const RenderPdfSombra = memo(() => {
  const { crumbs, loading, pdfDetails, pdfDownloads, handleDownloadPdf, loadingPdf } =
    useRenderPdfSombra();

  if (loading)
    return (
      <div className={`${CapacitacionStyles['container']} sombra-specs-body`}>
        <BreadCrumb crumbs={crumbs} />
        <div className="sombra-inform">
          <h2>Cargando pdf</h2>
        </div>
      </div>
    );

  if (!pdfDetails)
    return (
      <div className={`${CapacitacionStyles['container']} sombra-specs-body`}>
        <BreadCrumb crumbs={crumbs} />

        <div className="sombra-inform">
          <h2>No existe un pdf sombra para este usuario</h2>
        </div>
      </div>
    );
  return (
    <div className={`${CapacitacionStyles['container']} sombra-specs-body`}>
      <BreadCrumb crumbs={crumbs} />

      <button
        className="pdf-sombra-download-button"
        disabled={loadingPdf}
        onClick={handleDownloadPdf}
      >
        {loadingPdf
          ? 'Creando archivo'
          : pdfDownloads
            ? 'Volver a generar pdf sombra'
            : 'Generar pdf sombra'}
      </button>

      <div className="sombra-inform">
        <div className="banner" />
        <h1>Proceso Sombra</h1>
        <h3>Resultados Nivel Básico Capacitación</h3>
        <Table1 />
        <Table2 />
        <TableGraph3 />
        <TablaRubricaDetail7 />
        <br />
        <TablaRubricaDetailPar9 />
      </div>
    </div>
  );
});
RenderPdfSombra.displayName = 'RenderPdfSombra';
export default RenderPdfSombra;
