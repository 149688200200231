import React, { useState } from 'react';
import '../updateDbModal/updateDbModal.css';
import Upload from '../../assets/upload-logo.png';
import { uploadCompararGrupos } from '../../services/uploadCompararGrupos';

function UpdateCompararModal({
  setSuccessMessage,
  isCompararModal,
  isPostSuccess,
  isLoadingAlert,
  tipo
}: any) {
  const [colabsFile, setColabsFile] = useState<File | null>(null);
  const [_, setColabsFileConverted] = useState<string>('');
  const [jsonFile, setJsonFile] = useState<any>(null);
  const [__, setPostResponse] = useState<any>(null);

  const changeModalState = () => {
    isCompararModal(false);
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    const lector = new FileReader();

    lector.onload = () => {
      const fileConverted = lector.result as string;
      const base64File = fileConverted.split(',')[1];
      setColabsFileConverted(base64File);
      const objetoJson = {
        file: base64File
      };

      setJsonFile(objetoJson);
      // console.log('archivo convertido', fileConverted);
      // console.log('base64', base64File);
      // console.log('json', objetoJson);
    };

    if (file) {
      lector.readAsDataURL(file);
    }

    setColabsFile(file);
  };

  const uploadCompararGruposFile = () => {
    isLoadingAlert(true);

    const token: any = sessionStorage.getItem('token');
    const document: any = jsonFile;

    uploadCompararGrupos(document, token, tipo)
      .then((response: any) => {
        setPostResponse(response);

        if (response.success === true) {
          isPostSuccess(true);
          setSuccessMessage('El archivo se cargó correctamente');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          isPostSuccess(false);
        }

        isLoadingAlert(false);
        setJsonFile(null);
      })
      .catch((error) => {
        console.error('Error al cargar archivo', error);
      });

    changeModalState();
  };

  return (
    <div className="updatedb-modal">
      <div className="updatedb-container">
        <p>Cursos online</p>
        <div className="input-container">
          <div className="upload-file">
            <img src={Upload} alt="upload-logo" />
            <p>{colabsFile === null ? 'Cargar archivo' : colabsFile.name}</p>
          </div>
          <input type="file" id="uploadDb" name="Cargar archivo" onChange={handleFile} />
        </div>
        <div className="upload-file-buttons">
          <button className="cancel-button" onClick={changeModalState}>
            CANCELAR
          </button>
          <button
            className={`accept-button ${jsonFile == null ? 'disabled' : ''}`}
            onClick={uploadCompararGruposFile}
            disabled={jsonFile == null}
          >
            ACEPTAR
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateCompararModal;
