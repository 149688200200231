import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import grayStar from '../../assets/grayStar.svg';
import greenStar from '../../assets/greenStar.svg';
import yellowStar from '../../assets/yellowStar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setDynamicHeight, setDynamicHeight2 } from '../../redux/pdfSombra/pdfSombraActions';
import { AppState } from '../../types/types';
const CardL = memo(
  ({
    nivel,
    porcentaje,
    estado,
    calificacionFormador,
    fecha,
    sombraExperto,
    sombraLider,
    colaborador,
    tituloFormador,
    tituloOnline,
    calificacionOnline,
    step,
    leftImage,
    rigthImage,
    leftTrace,
    rigthTrace
  }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { nomina } = useParams<{ nomina: string }>();

    const disabledPdfbutton = colaborador
      ? !colaborador?.proceso_sombra_status?.base_lider ||
        !colaborador?.proceso_sombra_status?.base_par
      : true;

    const toggleCollapse = () => {
      let newPorcentage = parseInt(porcentaje);
      if (typeof porcentaje === 'string') newPorcentage = parseInt(porcentaje);
      if (newPorcentage === 0) return;
      setIsOpen(!isOpen);
    };
    const getImage = () => {
      let newPorcentage = parseInt(porcentaje);
      if (typeof porcentaje === 'string') newPorcentage = parseInt(porcentaje);
      if (newPorcentage === 100) return greenStar;
      if (newPorcentage < 100 && newPorcentage > 0) return yellowStar;
      return grayStar;
    };
    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }, [isOpen]);

    const redirectToPdfSombra = () => {
      // Obtiene la ruta base de la aplicación
      /*   const basePath = window.location.origin; // Esto obtiene el dominio actual
            const relativePath = '/pdf-sombra'; // Ruta que deseas concatenar
            const fullPath = `${basePath}${relativePath}`; // Concatenar el dominio con la ruta
            const options = 'width=1240,height=900,left=100,top=100,scrollbars=yes,resizable=yes';
            window.open(fullPath); */
      navigate(`/trackAnual/detalledeTrackCapacitacion/${nomina}/pdf-sombra`);
      // Redirigir a la nueva ruta
      /*    window.open(fullPath, '_blank', options); // Abre en la misma ventana */
      // Si deseas abrir en una nueva ventana, usa: window.open(fullPath, '_blank');
    };

    const returnPercentageClass = () => {
      if (porcentaje === 50) return 'green';
      if (porcentaje > 50) return 'blue';
      return 'gray';
    };

    const dispatch = useDispatch();
    const { dynamicHeight, dynamicHeight2 } = useSelector((state: AppState) => state.pdfSombra);

    useEffect(() => {
      const DynamicHeightComponent = () => {
        const step1 = document.getElementById(`level-container-1`);
        const step2 = document.getElementById(`level-container-2`);
        const step3 = document.getElementById(`level-container-3`);
        if (step1 && step2 && step3) {
          const rect1 = step1.getBoundingClientRect();
          const rect2 = step2.getBoundingClientRect();
          const rect3 = step3.getBoundingClientRect();
          const distancia1 = Math.abs(rect1.top - rect2.top);
          const distancia2 = Math.abs(rect2.top - rect3.top);
          dispatch(setDynamicHeight(distancia1));
          dispatch(setDynamicHeight2(distancia2));
        }
      };
      setTimeout(() => {
        DynamicHeightComponent();
      }, 250);
    }, [isOpen]);

    function validarEstado(estado: any) {
      // Convertir el estado a minúsculas
      const estadoMinusculas = estado.toLowerCase();
      // Realizar la validación
      return estadoMinusculas.includes('pendiente de realización') ||
        estadoMinusculas.includes('no completado')
        ? 'error'
        : '';
    }

    return (
      <div className={`level-container`}>
        {leftImage && !rigthImage && leftImage}
        <div className="image-container">
          <img src={getImage()} alt={`${getImage()}`} id={`level-container-${step}`} />
          {leftTrace && (
            <div
              style={{ height: `${step === '1' ? dynamicHeight : dynamicHeight2}px` }}
              className={`trace-container ${isOpen ? 'isOpen' : ''}`}
              id={`trace-${step}`}
            >
              <svg
                width="5"
                height="500"
                viewBox="0 0 5 500"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="2.5"
                  y1="1.99902"
                  x2="2.5"
                  y2="500.02"
                  stroke="#C3CDC7"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeDasharray="8 16"
                />
              </svg>
            </div>
          )}
        </div>

        <div className={`nivel-card ${returnPercentageClass()}`}>
          <div className={`nivel-card-header ${isOpen ? 'isOpen' : ''}`} onClick={toggleCollapse}>
            <span className={`nivel-card-nivel`}>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7 7L13 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="nivel-card-porcentaje" style={{ textAlign: 'center' }}>
              <span>{nivel}</span>
              <br />
              {porcentaje}%
            </span>
            <span className="nivel-card-icono">
              <div className="step-icon">{step}</div>
            </span>
          </div>
          <div
            className={`nivel-card-content ${isOpen ? 'isOpen' : ''}`}
            ref={contentRef}
            style={{
              maxHeight: isOpen ? `${contentHeight}px` : '0',
              opacity: isOpen ? 1 : 0
            }}
          >
            <div className={`nivel-card-estado ${validarEstado(estado)}`}>{estado}</div>

            {tituloOnline && calificacionOnline && (
              <>
                <div className="nivel-card-calificacion">{tituloOnline}</div>
                <div className={`nivel-card-estado number ${calificacionOnline}`}>
                  {calificacionOnline}
                </div>
              </>
            )}
            <div className="nivel-card-calificacion">{tituloFormador}</div>
            <div className={`nivel-card-estado number ${calificacionFormador}`}>
              {calificacionFormador}
            </div>
            <div className="nivel-card-info">
              <div className="info-item">
                <span>Fecha de finalización</span>
                <span>{fecha}</span>
              </div>
              {sombraExperto && (
                <div className="info-item">
                  <span>
                    Sombra Estatus <br /> Par Experto
                  </span>
                  <span className={`status ${sombraExperto === 'Realizado' ? 'experto' : 'lider'}`}>
                    {colaborador?.proceso_sombra ? sombraExperto : 'No aplica'}
                  </span>
                </div>
              )}
              {sombraLider && (
                <div className="info-item">
                  <span>
                    Sombra Estatus <br /> Par Líder
                  </span>
                  <span className={`status ${sombraLider === 'Realizado' ? 'experto' : 'lider'}`}>
                    {colaborador?.proceso_sombra ? sombraLider : 'No aplica'}
                  </span>
                </div>
              )}
            </div>
            {colaborador?.proceso_sombra && (
              <button
                disabled={disabledPdfbutton}
                onClick={redirectToPdfSombra}
                className={`nivel-card-boton ${disabledPdfbutton ? 'disabled' : ''}`}
              >
                VER RESUMEN PROCESO SOMBRA
              </button>
            )}
          </div>
        </div>

        {rigthImage && rigthImage}
        {/* {rigthTrace && (
          <div className={`trace-container right  ${isOpen ? 'isOpen' : ''}`}>
            <svg
              width="122"
              height="66"
              viewBox="0 0 122 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M119.922 63.7133C51.2179 53.044 23.9229 36.9859 1.99943 1.99989"
                stroke="#C0E0CC"
                strokeWidth="4"
                strokeLinecap="round"
                strokeDasharray="4 15"
              />
            </svg>
          </div>
        )} */}
      </div>
    );
  }
);
CardL.displayName = 'CardL';
export default CardL;
